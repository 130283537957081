import loadable from '@loadable/component';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as React from 'react';
import 'ui/css/App.css';
import { APP_THEME } from 'Config';
import Footer from 'ui/Footer';

const HOME = loadable(() =>
  import(/* webpackChunkName: "HomePage" */ './pages/Home')
);

const HISTORY = loadable(() =>
  import(/* webpackChunkName: "HistoryPage" */ './pages/History'),
);

export class App extends React.PureComponent {
  render() {
    return (
      <MuiThemeProvider theme={APP_THEME}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route exact={true} path="/" component={HOME} />
            <Route exact={true} path="/history" component={HISTORY} />
          </Switch>
        </BrowserRouter>
        <Footer />
      </MuiThemeProvider>
    );
  }
}
