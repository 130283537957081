import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { AppInfo } from 'DataTypes';

interface Props {
  info: AppInfo;
}

// tslint:disable-next-line: no-default-export
export default class Info extends React.PureComponent<Props> {
  render() {
    const info = this.props.info;

    return (
      <React.Fragment>
        <Typography variant="h5" color="primary">
          <b>{info.name}</b>
        </Typography>
        <Typography variant="caption" color="secondary">
          Served via <b>{info.servedVia}</b>
        </Typography>
        <Typography variant="subtitle2" color="secondary">
          <u>Frontend {info.frontendVersion}</u>
        </Typography>
        <Typography variant="caption">
          <ul>
            {info.frontendTechnology.map((technology, index) => {
              return <li key={index}>{technology}</li>;
            })}
          </ul>
        </Typography>
        <Typography variant="subtitle2" color="secondary">
          <u>Backend {info.backendVersion}</u>
        </Typography>
        <Typography variant="caption">
          <ul>
            {info.backendTechnology.map((technology, index) => {
              return <li key={index}>{technology}</li>;
            })}
          </ul>
        </Typography>
      </React.Fragment>
    );
  }
}
