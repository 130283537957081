import Button from '@material-ui/core/Button';
import * as React from 'react';
import 'ui/css/LinkButton.css';

interface Props {
  className: string;
  url: string;
}

// tslint:disable-next-line: no-default-export
export default class LinkButton extends React.PureComponent<Props> {
  render() {
    return (
      <Button
        onClick={this.openLink}
        color="secondary"
        size="small"
        variant="outlined"
        className={`link_button ${this.props.className}`}
      >
        {this.props.children}
      </Button>
    );
  }

  private openLink = () =>
    window.open(this.props.url, '_blank', 'noopener noreferrer');
}
