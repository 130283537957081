import * as React from 'react';
import 'ui/css/Footer.css';
import { APP_LINKS, APP_INFO } from 'Config';
import Links from 'ui/Footer/Links';
import Version from 'ui/Footer/Version';

// tslint:disable-next-line: no-default-export
export default class Footer extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Links className="links" links={APP_LINKS} />
        <Version className="version" info={APP_INFO} />
      </React.Fragment>
    );
  }
}
