import Popover from '@material-ui/core/Popover';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import 'ui/css/Version.css';
import { AppInfo } from 'DataTypes';
import Info from 'ui/Footer/Info';

interface Props {
  className: string;
  info: AppInfo;
}

interface State {
  anchorEl?: Element;
}

// tslint:disable-next-line: no-default-export
export default class Version extends React.PureComponent<Props, State> {
  state = {
    anchorEl: undefined,
  };

  render() {
    return (
      <React.Fragment>
        <Slide direction="up" in={true}>
          <Typography
            className={`${this.props.className}`}
            variant="subtitle2"
            onClick={this.openInfo}
          >
            <i>by {this.props.info.creator}</i>{' '}
            {this.props.info.frontendVersion}⁽ⁱ⁾
          </Typography>
        </Slide>
        <Popover
          open={Boolean(this.state.anchorEl)}
          className="info"
          anchorEl={this.state.anchorEl}
          onClose={this.closeInfo}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Info info={this.props.info} />
        </Popover>
      </React.Fragment>
    );
  }

  private openInfo = (event: React.MouseEvent) =>
    this.setState({ anchorEl: event.currentTarget });
  private closeInfo = () => this.setState({ anchorEl: undefined });
}
