import teal from '@material-ui/core/colors/teal';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import {
  Palette,
  TypeBackground,
  PaletteColorOptions,
} from '@material-ui/core/styles/createPalette';
import CodeIcon from '@material-ui/icons/Code';
import SubjectIcon from '@material-ui/icons/Subject';
import * as React from 'react';
import { LinkData, AppInfo } from 'DataTypes';

export const APP_THEME: Readonly<Theme> = createMuiTheme({
  palette: {
    background: {
      default: teal[50],
      paper: teal[100],
    } as Readonly<TypeBackground>,
    primary: {
      main: teal[900],
    } as Readonly<PaletteColorOptions>,
    secondary: {
      main: teal[500],
    } as Readonly<PaletteColorOptions>,
    type: 'light',
  } as Readonly<Palette>,
});

export const APP_LINKS: ReadonlyArray<LinkData> = [
  {
    icon: <CodeIcon />,
    url: 'https://gitlab.com/harry.sky.vortex/melodiam',
  },
  {
    icon: <SubjectIcon />,
    iconSide: 'left',
    text: 'Blog',
    url: 'https://blog.neigor.me/music',
  },
];

export const APP_INFO: Readonly<AppInfo> = {
  name: 'Melodiam',
  creator: 'Igor N.',
  frontendVersion: 'v2.1.1',
  frontendTechnology: [
    'React (TypeScript)',
    'Redux + Redux-Saga',
    'Material-UI',
    'react-router-dom',
    '@loadable/component',
    'axios',
    'Storybook.js',
  ],
  backendVersion: 'v2.1.1',
  backendTechnology: [
    'Starlette (Python 3.7)',
    'uvicorn',
    'httpx',
    'tekore',
    'apscheduler',
  ],
  servedVia: 'Docker (nginx, python)',
};
