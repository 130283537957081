import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slide from '@material-ui/core/Slide';
import * as React from 'react';
import 'ui/css/Links.css';
import LinkButton from 'ui/LinkButton';
import { LinkData } from 'DataTypes';

interface Props {
  className: string;
  links: ReadonlyArray<LinkData>;
}

// tslint:disable-next-line: no-default-export
export default class Links extends React.PureComponent<Props> {
  render() {
    return (
      <Slide direction="up" in={true}>
        <ButtonGroup aria-label="links" className={this.props.className}>
          {this.props.links.map((link, index) => {
            const iconSide =
              link.icon && link.iconSide ? `${link.iconSide}_icon` : '';

            return (
              <LinkButton key={index} url={link.url} className={`${iconSide}`}>
                {link.icon && link.iconSide !== 'right' && link.icon}
                {link.text}
                {link.icon && link.iconSide === 'right' && link.icon}
              </LinkButton>
            );
          })}
        </ButtonGroup>
      </Slide>
    );
  }
}
